import {
  componentInterface,
  ConditionalFormattingState,
} from '@ardoq/component-interface';
import type { Rectangle, Vector } from '@ardoq/graph';

import { ArdoqIconCategory, IconName, fontAwesomeIcons } from '@ardoq/icons';

import {
  canvasResolveImage,
  canvasResolvedImages,
  svgImage,
} from 'tabview/canvasRendering/canvasResolvedImages';

import {
  BLOCKS_VIEW_NODE_PAD,
  BLOCKS_VIEW_LEAF_RD_OUTERSIZE,
  BLOCKS_VIEW_LEAF_BACKGROUNDCOLOR,
  BLOCKS_VIEW_LEAF_BORDERCOLOR,
  BLOCKS_VIEW_GROUP_BADGE_FONT,
  BLOCKS_VIEW_NODE_MARGIN,
  BLOCKS_VIEW_NODE_BORDERRADIUS,
  BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT,
  BLOCKS_VIEW_NODE_LABEL_FONT,
  BLOCKS_VIEW_CONTEXT_COLOR,
  BLOCKS_VIEW_NODE_SEP,
  BLOCKS_VIEW_LEAF_RD_INNERSIZE,
  BLOCKS_VIEW_LEAF_BORDERWIDTH,
  BLOCKS_VIEW_NODE_LABEL_WRAP as BLOCKS_VIEW_GROUP_LABELWRAP,
  BLOCKS_VIEW_MULTILABEL_FONTHEIGHT,
  BLOCKS_VIEW_GROUP_BACKGROUNDCOLOR,
  BLOCKS_VIEW_MULTILABEL_BORDERCOLOR,
  BLOCKS_VIEW_GROUP_BORDERCOLOR,
  BLOCKS_VIEW_GROUP_BORDERWIDTH,
  BLOCKS_VIEW_CONTEXT_WIDTH,
  BLOCKS_VIEW_MULTILABEL_FONT,
  BLOCKS_VIEW_GROUP_MULTILABEL_BACKGROUNDCOLOR,
  BLOCKS_VIEW_LEAF_MULTILABEL_BACKGROUNDCOLOR,
  BLOCKS_VIEW_NODE_SUBLABEL_FONT,
  BLOCKS_VIEW_NODE_MINIMUM_WIDTH,
  BLOCKS_VIEW_NODE_MINIMUM_HEIGHT,
  BLOCKS_VIEW_COLLAPSED_GROUP_MINIMUM_HEIGHT,
  BLOCKS_VIEW_MULTILABEL_COLOR,
  BLOCKS_VIEW_MULTILABEL_BORDERRADIUS,
  BLOCKS_VIEW_GROUP_PAD,
  BLOCKS_VIEW_NODE_LABEL_LINE_HEIGHT,
  BLOCKS_VIEW_MULTILABEL_PADDING_VERTICAL,
  BLOCKS_VIEW_MULTILABEL_PADDING_HORIZONTAL,
  BLOCKS_VIEW_MULTILABEL_LINE_HEIGHT,
} from '../consts';
import {
  type BlocksViewNode,
  BlocksViewVisualState,
  type RectangleSides,
} from '../types';
import { CanvasRenderingContext } from './visual';
import { getDescendantCount, isGroup } from '../viewModel$/util';
import {
  rectBloat,
  rectCenter,
  rectContains,
  rectHeight,
  rectWidth,
  vectorMag,
} from '../misc/geometry';

import { getHoverColor, getTextColor } from '../misc/color';
import { currentTimestamp } from '@ardoq/date-time';
import { sum, sumBy } from 'lodash';
import { wrapString } from '../misc/string';
import { readableButtonColor } from 'tabview/blockDiagram/view/yFilesExtensions/modernized/utils';
import canvasLabelMeasurer from './canvasLabelMeasurer';
import { colors } from '@ardoq/design-tokens';
import { determineStyleSize } from 'yfilesExtensions/styles/util';

const isSyntheticGroup = (node: BlocksViewNode) => {
  return isGroup(node) && !node.parent;
};

const groupExpanderRect = (cell: Rectangle): Rectangle => {
  const sz = HEADER_INNER_HEIGHT;
  const margin = BLOCKS_VIEW_NODE_MARGIN;
  const pad = BLOCKS_VIEW_GROUP_PAD;

  const left = cell[0] + BLOCKS_VIEW_NODE_MARGIN + pad;
  const top = cell[1] + margin + pad;

  return [left, top, left + sz, top + sz];
};

// this is a (typically) fairly pessimistic way of measuring a string width.
// although it might get it wrong sometimes it has the advantage of being fast
// and not requiring a canvas rendering context. adding a canvas rendering context
// is probably a better long-term solution but may require some modifications in
// the layout code which are probably better left until the constraint solver
// is updated to become 2D (which is pretty much required before a proper release)
const approxMeasure = (a: string) =>
  a.length * 0.6 * BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT;

const measureNodeLabel = canvasLabelMeasurer(BLOCKS_VIEW_NODE_LABEL_FONT);
const approxLabelWidth = (label: string[]) => {
  let ret = 0;

  for (const line of label) {
    ret = Math.max(ret, approxMeasure(line));
  }

  return ret;
};

const exactLabelWidth = (label: string[], ctx: CanvasRenderingContext) => {
  let ret = 0;

  for (const line of label) {
    ret = Math.max(ret, ctx.measureText(line).width);
  }

  return ret;
};

const getLabelHeight = (label: string[], fontSize: number) => {
  return label.length * fontSize;
};

const getMultilabelsHeight = ({
  displayLabel: [, , ...multiLabels],
}: BlocksViewNode) =>
  sumBy(
    multiLabels,
    multiLabel =>
      2 * BLOCKS_VIEW_MULTILABEL_PADDING_VERTICAL +
      multiLabel.length * BLOCKS_VIEW_MULTILABEL_LINE_HEIGHT
  );

const getNodeMinimumCellWidth = (node: BlocksViewNode) => {
  if (isSyntheticGroup(node)) {
    return (
      BLOCKS_VIEW_NODE_MARGIN + sum(node.colSize) + BLOCKS_VIEW_NODE_MARGIN
    );
  }

  if (!isGroup(node) || !node.open) {
    return BLOCKS_VIEW_NODE_MINIMUM_WIDTH;
  }

  /* expander, sep, rd, sep, label */

  const maxTitleWidth = BLOCKS_VIEW_GROUP_LABELWRAP;
  const label = wrapString(
    node.labels[0],
    BLOCKS_VIEW_GROUP_LABELWRAP,
    approxMeasure
  );
  const widthHeader =
    BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT +
    BLOCKS_VIEW_NODE_SEP +
    BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT +
    BLOCKS_VIEW_NODE_SEP +
    approxLabelWidth(label);

  /* sublabel */

  const sublabel = wrapString(node.labels[1], maxTitleWidth, approxMeasure);
  const widthSublabel = approxLabelWidth(sublabel);

  /* multilabel */

  let widthMultilabel = 0;
  for (let i = 2; i < node.labels.length; ++i) {
    const wrapped = wrapString(node.labels[i], maxTitleWidth, approxMeasure);
    const width =
      BLOCKS_VIEW_NODE_SEP +
      wrapped.reduce((max, line) => Math.max(max, approxMeasure(line)), 0) +
      1 +
      BLOCKS_VIEW_NODE_SEP;

    widthMultilabel = Math.max(widthMultilabel, width);
  }

  /* columns */

  const widthColumns = sum(node.colSize);

  /* get the minimum width */

  const width =
    BLOCKS_VIEW_NODE_MARGIN +
    BLOCKS_VIEW_GROUP_PAD +
    Math.max(widthHeader, widthSublabel, widthMultilabel, widthColumns) +
    BLOCKS_VIEW_GROUP_PAD +
    BLOCKS_VIEW_NODE_MARGIN;

  return Math.max(BLOCKS_VIEW_NODE_MINIMUM_WIDTH, width);
};
/** the height of the header inside its padding, where the expander, label, and badge are drawn. */
const HEADER_INNER_HEIGHT = 20;
const COLLAPSED_GROUP_HEADER_HEIGHT =
  HEADER_INNER_HEIGHT + 2 * BLOCKS_VIEW_GROUP_PAD;
const getNodeMinimumCellHeight = (node: BlocksViewNode, width: number) => {
  const getMultilabelHeight = (space: number) => {
    // this should take into account that I have a bit less space

    const multilabelCount = node.labels.length - 2;

    let ret = 0;

    if (multilabelCount > 0) {
      ret += (multilabelCount - 1) * BLOCKS_VIEW_NODE_SEP;

      for (let i = 2; i < node.labels.length; ++i) {
        const label = wrapString(node.labels[i], space, approxMeasure);
        const lineCount = label.length;

        ret += lineCount * BLOCKS_VIEW_MULTILABEL_FONTHEIGHT;
      }
    }

    return ret;
  };

  if (isSyntheticGroup(node)) {
    return (
      BLOCKS_VIEW_NODE_MARGIN + sum(node.rowSize) + BLOCKS_VIEW_NODE_MARGIN
    );
  }

  /* find out how much horizontal space we have */
  const { open } = node;
  const isGroupNode = isGroup(node);
  const isOpenGroup = isGroupNode && open;
  const nodePadding = isOpenGroup
    ? BLOCKS_VIEW_GROUP_PAD
    : BLOCKS_VIEW_NODE_PAD;
  const space =
    width -
    (BLOCKS_VIEW_NODE_MARGIN +
      nodePadding +
      nodePadding +
      BLOCKS_VIEW_NODE_MARGIN);

  const labelSpace =
    space - 2 * (BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT + BLOCKS_VIEW_NODE_SEP);

  const heightLabel =
    BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT *
    wrapString(node.labels[0], labelSpace, approxMeasure).length;

  const heightMultilabel = getMultilabelHeight(
    space - BLOCKS_VIEW_NODE_SEP - BLOCKS_VIEW_NODE_SEP
  );
  if (!isGroupNode) {
    const height =
      BLOCKS_VIEW_NODE_MARGIN +
      BLOCKS_VIEW_NODE_PAD +
      BLOCKS_VIEW_LEAF_RD_OUTERSIZE +
      BLOCKS_VIEW_NODE_SEP +
      (heightLabel !== 0 ? heightLabel + BLOCKS_VIEW_NODE_SEP : 0) +
      (heightMultilabel !== 0 ? heightMultilabel + BLOCKS_VIEW_NODE_SEP : 0) +
      BLOCKS_VIEW_NODE_PAD +
      BLOCKS_VIEW_NODE_MARGIN;
    return Math.max(BLOCKS_VIEW_NODE_MINIMUM_HEIGHT, height);
  }

  if (!open) {
    const height =
      COLLAPSED_GROUP_HEADER_HEIGHT +
      BLOCKS_VIEW_NODE_MARGIN +
      BLOCKS_VIEW_NODE_PAD +
      BLOCKS_VIEW_LEAF_RD_OUTERSIZE +
      BLOCKS_VIEW_NODE_SEP +
      (heightLabel !== 0 ? heightLabel + BLOCKS_VIEW_NODE_SEP : 0) +
      (heightMultilabel !== 0 ? heightMultilabel + BLOCKS_VIEW_NODE_SEP : 0) +
      BLOCKS_VIEW_NODE_PAD +
      BLOCKS_VIEW_NODE_MARGIN;

    return Math.max(BLOCKS_VIEW_COLLAPSED_GROUP_MINIMUM_HEIGHT, height);
  }

  const heightSublabel =
    BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT *
    wrapString(node.labels[1], space, approxMeasure).length;
  const heightRows = node.rowSize ? sum(node.rowSize) : 0;

  const height =
    BLOCKS_VIEW_NODE_MARGIN +
    BLOCKS_VIEW_GROUP_PAD +
    (heightLabel !== 0 ? heightLabel + BLOCKS_VIEW_NODE_SEP : 0) +
    (heightSublabel !== 0 ? heightSublabel + BLOCKS_VIEW_NODE_SEP : 0) +
    (heightMultilabel !== 0 ? heightMultilabel + BLOCKS_VIEW_NODE_SEP : 0) +
    BLOCKS_VIEW_NODE_SEP +
    heightRows +
    BLOCKS_VIEW_GROUP_PAD +
    BLOCKS_VIEW_NODE_MARGIN;

  return Math.max(BLOCKS_VIEW_NODE_MINIMUM_HEIGHT, height);
};

export const getNodeMinimumCellSize = (node: BlocksViewNode): Vector => {
  // this is used in for simultaneous constraint solving, but would
  // be replaced by separate calls for orthogonal constraint solving

  const w = getNodeMinimumCellWidth(node);
  const h = getNodeMinimumCellHeight(node, w - 1); // patch the numerous off-by-ones. TODO don't have the off-by-ones in the first place

  return [w, h];
};

export const setNodeCellRect = (node: BlocksViewNode, cell: Rectangle) => {
  if (node.cell) {
    node.animation = {
      begin: currentTimestamp(),
      duration: 500,
      from: node.cell,
    };
  }

  node.cell = [...cell];
  node.bounds = rectBloat(node.cell, -BLOCKS_VIEW_NODE_MARGIN);
  node.content = undefined;
  node.displayLabel = [];

  if (isSyntheticGroup(node)) {
    node.content = [...node.bounds];
    return;
  }

  const space =
    rectWidth(node.bounds) - (BLOCKS_VIEW_NODE_PAD + BLOCKS_VIEW_NODE_PAD);

  if (!isGroup(node) || !node.open) {
    const multilabelSpace = space - 2 * BLOCKS_VIEW_NODE_SEP;

    node.displayLabel.push(wrapString(node.labels[0], space, measureNodeLabel));
    node.displayLabel.push(wrapString(node.labels[1], space, measureNodeLabel));

    for (let i = 2; i < node.labels.length; ++i) {
      node.displayLabel.push(
        wrapString(node.labels[i], multilabelSpace, approxMeasure)
      );
    }

    return;
  }

  /* wrap strings to their final resting place */

  const labelSpace =
    space - 2 * (BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT + BLOCKS_VIEW_NODE_SEP);
  const multilabelSpace = space - 2 * BLOCKS_VIEW_NODE_SEP;

  node.displayLabel.push(wrapString(node.labels[0], labelSpace, approxMeasure));
  node.displayLabel.push(wrapString(node.labels[1], space, approxMeasure));

  for (let i = 2; i < node.labels.length; ++i) {
    node.displayLabel.push(
      wrapString(node.labels[i], multilabelSpace, approxMeasure)
    );
  }

  const y =
    node.bounds[1] +
    BLOCKS_VIEW_NODE_PAD +
    getLabelHeight(node.displayLabel[0], BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT) +
    getMultilabelsHeight(node) +
    BLOCKS_VIEW_NODE_SEP;

  node.content = [
    node.bounds[0] + BLOCKS_VIEW_NODE_PAD,
    y,
    node.bounds[2] - BLOCKS_VIEW_NODE_PAD,
    node.bounds[3] - BLOCKS_VIEW_NODE_PAD,
  ];
};

export const hitTestNode = (node: BlocksViewNode, pos: Vector) => {
  if (isGroup(node) && hitTestExpander(node, pos)) {
    return true;
  }

  if (!node.cell || !rectContains(node.cell, pos)) {
    /* cell rect doesn't contain point */
    return false;
  }

  return rectContains(node.bounds, pos);
};

export const hitTestExpander = (node: BlocksViewNode, pos: Vector) => {
  if (!isGroup(node) || !node.cell) {
    return false;
  }

  const expanderRect = groupExpanderRect(node.cell);

  if (!rectContains(expanderRect, pos)) {
    return false;
  }

  const c = rectCenter(expanderRect);
  const r = 0.5 * Math.min(rectWidth(expanderRect), rectHeight(expanderRect));

  if (vectorMag([pos[0] - c[0], pos[1] - c[1]]) > r) {
    return false;
  }

  return true;
};

const getNodeBounds = (
  _node: BlocksViewNode,
  cell: RectangleSides
): RectangleSides => {
  return BLOCKS_VIEW_NODE_MARGIN
    ? rectBloat(cell, -BLOCKS_VIEW_NODE_MARGIN)
    : cell;
};

let ctx: CanvasRenderingContext | null = null; // this is global rendering context
let pixel: number = 0; // pixel gets used all over the fucking place too

const getNodeColor = (node: BlocksViewNode) => {
  const displayColor =
    componentInterface.getComponentDisplayColorAsSVGAttributes(node.modelId!, {
      useAsBackgroundStyle: false,
    });

  return displayColor.fill ?? 'transparent';
};
const drawExpander = (
  node: BlocksViewNode,
  rc: Rectangle,
  textColor: string
) => {
  const x = 0.5 * (rc[2] + rc[0]);
  const y = 0.5 * (rc[3] + rc[1]);
  const expanderSize = Math.min(rectWidth(rc), rectHeight(rc));

  if (expanderSize > 0) {
    ctx!.font = `${expanderSize}px Material Icons Round`;
    ctx!.fillStyle = textColor;
    ctx!.textBaseline = 'middle';
    ctx!.textAlign = 'center';
    ctx!.fillText(
      node.open ? IconName.UNFOLD_LESS : IconName.UNFOLD_MORE,
      x,
      y
    );
  }
};

const drawBadge = (
  node: BlocksViewNode,
  rc: Rectangle,
  textColor: string,
  fillColor: string
) => {
  const text = getDescendantCount(node).toString();

  ctx!.fillStyle = fillColor;
  ctx!.beginPath();
  ctx!.ellipse(
    ...rectCenter(rc),
    0.5 * rectWidth(rc),
    0.5 * rectHeight(rc),
    0,
    0,
    2 * Math.PI
  );
  ctx!.fill();

  ctx!.fillStyle = textColor;
  ctx!.textAlign = 'center';
  ctx!.textBaseline = 'middle';
  ctx!.font = BLOCKS_VIEW_GROUP_BADGE_FONT;
  ctx!.fillText(text, ...rectCenter(rc));
};

const roundRect = (
  rc: RectangleSides,
  radius: number | Iterable<number | DOMPointInit>
) => {
  ctx!.roundRect(rc[0], rc[1], rectWidth(rc), rectHeight(rc), radius);
};

const SMALL_SEP = 4;

const drawShape = (
  ctx: CanvasRenderingContext,
  fg: string,
  bg: string,
  shapeName: string,
  [x, y, width, height]: Rectangle
) => {
  const img = svgImage(
    `${shapeName}~~${colors.black}~~${fg}~~${fg}~~${width}~~${height}`
  );

  ctx.fillStyle = bg;
  ctx.strokeStyle = fg;
  ctx.drawImage(img, x, y);
};
export enum DrawNodeOpacity {
  LOW_LIGHT = 0.2,
  DRAG_SOURCE = 0.32,
  NORMAL = 1,
}
export const drawNode = (
  node: BlocksViewNode,
  cell: Rectangle,
  opacity: DrawNodeOpacity,
  context: CanvasRenderingContext
) => {
  if (!node.parent) {
    return;
  }

  ctx = context;
  pixel = 1.0 / context.getTransform().a;

  const isHighlight = node.visualState & BlocksViewVisualState.Highlight;
  const isHover = node.visualState & BlocksViewVisualState.Hover;
  const isSelect = node.visualState & BlocksViewVisualState.Select;
  const nodeColor = isHover
    ? getHoverColor(getNodeColor(node))
    : getNodeColor(node);
  const contrastColor = getTextColor(nodeColor);

  const baseColor = isGroup(node)
    ? BLOCKS_VIEW_GROUP_BACKGROUNDCOLOR
    : BLOCKS_VIEW_LEAF_BACKGROUNDCOLOR;
  const bg = isHover ? getHoverColor(baseColor) : baseColor;
  const textColor = getTextColor(bg);

  const borderColor = isGroup(node)
    ? BLOCKS_VIEW_GROUP_BORDERCOLOR
    : BLOCKS_VIEW_LEAF_BORDERCOLOR;
  const lineWidth =
    pixel *
    (isHover ? 1.5 : 1.0) *
    (isGroup(node)
      ? BLOCKS_VIEW_GROUP_BORDERWIDTH
      : BLOCKS_VIEW_LEAF_BORDERWIDTH);

  const bounds = getNodeBounds(node, cell);
  const body = rectBloat(bounds, -BLOCKS_VIEW_NODE_PAD);

  const multilabelHeight = getMultilabelsHeight(node);
  const multilabelBg = isGroup(node)
    ? isHover
      ? getHoverColor(BLOCKS_VIEW_GROUP_MULTILABEL_BACKGROUNDCOLOR)
      : BLOCKS_VIEW_GROUP_MULTILABEL_BACKGROUNDCOLOR
    : isHover
      ? getHoverColor(BLOCKS_VIEW_LEAF_MULTILABEL_BACKGROUNDCOLOR)
      : BLOCKS_VIEW_LEAF_MULTILABEL_BACKGROUNDCOLOR;

  const globalAlpha = isHighlight ? 1 : opacity;
  ctx.globalAlpha = globalAlpha;

  const [mainLabel, subLabel, ...multiLabels] = node.displayLabel;

  const drawRepresentationData = (center: Vector, diameter: number) => {
    if (!node.representationData) {
      return;
    }

    const rd = node.representationData;
    const radius = 0.5 * diameter;

    const drawImage = () => {
      canvasResolveImage(rd);

      const img = canvasResolvedImages.get(rd.value!)!;

      if (img?.complete) {
        ctx!.fillStyle = nodeColor;
        ctx!.fillRect(
          center[0] - radius,
          center[1] - radius,
          2 * radius,
          2 * radius
        );
        const { conditionalFormattingState } = node;
        const isDimConditionalFormating =
          conditionalFormattingState === ConditionalFormattingState.DIM;
        ctx!.globalAlpha = isDimConditionalFormating ? 0.32 : 1;
        ctx!.drawImage(
          img,
          center[0] - radius,
          center[1] - radius,
          2 * radius,
          2 * radius
        );
        ctx!.globalAlpha = globalAlpha;
      }
    };

    const drawIcon = (fg: string) => {
      if (rd.icon && rd.icon.category === ArdoqIconCategory.FontAwesome) {
        ctx!.font = `${diameter}px FontAwesome`;
        ctx!.fillStyle = fg;
        ctx!.textBaseline = 'middle';
        ctx!.textAlign = 'center';
        ctx!.fillText(fontAwesomeIcons[rd.icon.id], ...center);

        return;
      }

      if (rd.icon?.isSVG) {
        const img = svgImage(`${rd.icon.id}~~${fg}`); // args interpolation: ${iconId}~~${color}~~${fill}~~${stroke}~~${width}~~${height}

        if (img) {
          ctx!.drawImage(
            img,
            center[0] - radius,
            center[1] - radius,
            diameter,
            diameter
          );
        }
      }
    };

    const clip = new Path2D();
    clip.ellipse(...center, radius, radius, 0, 0, 2 * Math.PI);

    ctx!.save();

    const isShape = !rd.isImage && rd.shapeName;
    if (!isShape) {
      ctx!.clip(clip);
    }

    if (rd.isImage) {
      drawImage();
      ctx!.restore();
      return;
    }

    if (rd.icon) {
      drawIcon(contrastColor);
      ctx!.restore();
      return;
    }

    ctx!.restore();
  };

  /** @returns the position under the bottom margin of the last label. */
  const drawMultilabels = (
    left: number,
    top: number,
    right: number,
    center: boolean
  ) => {
    ctx = ctx!;

    const leftAligned = (
      left: number,
      top: number,
      _right: number,
      label: string[]
    ) => {
      const w = exactLabelWidth(label, ctx!);
      const h = label.length * BLOCKS_VIEW_MULTILABEL_LINE_HEIGHT;
      const rc: RectangleSides = [
        left,
        top,
        left +
          BLOCKS_VIEW_MULTILABEL_PADDING_HORIZONTAL +
          w +
          BLOCKS_VIEW_MULTILABEL_PADDING_HORIZONTAL,
        top + h,
      ];

      ctx!.beginPath();
      roundRect(rc, BLOCKS_VIEW_MULTILABEL_BORDERRADIUS);
      ctx!.fillStyle = multilabelBg;
      ctx!.fill();
      ctx!.stroke();

      ctx!.fillStyle = BLOCKS_VIEW_MULTILABEL_COLOR;
      ctx!.textAlign = 'left';
      ctx!.textBaseline = 'middle';

      for (let i = 0; i < label.length; ++i) {
        const y = rc[1] + (i + 0.5) * BLOCKS_VIEW_MULTILABEL_LINE_HEIGHT;
        ctx!.fillText(
          label[i],
          rc[0] + BLOCKS_VIEW_MULTILABEL_PADDING_HORIZONTAL,
          y
        );
      }

      return h;
    };

    const centered = (
      left: number,
      top: number,
      right: number,
      label: string[]
    ) => {
      const w = exactLabelWidth(label, ctx!);
      const h = label.length * BLOCKS_VIEW_MULTILABEL_LINE_HEIGHT;

      const rc: RectangleSides = [
        0.5 * (left + right - w) - BLOCKS_VIEW_MULTILABEL_PADDING_HORIZONTAL,
        top,
        0.5 * (left + right + w) + BLOCKS_VIEW_MULTILABEL_PADDING_HORIZONTAL,
        top + h,
      ];
      const cx = 0.5 * (rc[0] + rc[2]);

      ctx!.beginPath();
      roundRect(rc, BLOCKS_VIEW_MULTILABEL_BORDERRADIUS);
      ctx!.fillStyle = multilabelBg;
      ctx!.fill();
      ctx!.stroke();

      ctx!.fillStyle = BLOCKS_VIEW_MULTILABEL_COLOR;
      ctx!.textAlign = 'center';
      ctx!.textBaseline = 'middle';

      for (let i = 0; i < label.length; ++i) {
        const y = rc[1] + (i + 0.5) * BLOCKS_VIEW_MULTILABEL_LINE_HEIGHT;
        ctx!.fillText(label[i], cx, y);
      }

      return h;
    };

    ctx.font = BLOCKS_VIEW_MULTILABEL_FONT;
    ctx.strokeStyle = BLOCKS_VIEW_MULTILABEL_BORDERCOLOR;

    let y = top;

    for (let i = 2; i < multiLabels.length; ++i) {
      const h = center
        ? centered(left, y, right, multiLabels[i])
        : leftAligned(left, y, right, multiLabels[i]);

      y += h + SMALL_SEP;
    }
    return y;
  };

  if (isSelect) {
    const BLOCKS_VIEW_SELECT_WIDTH = 1.5;
    const rect = rectBloat(bounds, 0.5 * BLOCKS_VIEW_SELECT_WIDTH * pixel);

    ctx.beginPath();
    roundRect(rect, BLOCKS_VIEW_NODE_BORDERRADIUS);
    ctx.lineWidth = BLOCKS_VIEW_SELECT_WIDTH * pixel;
    ctx.strokeStyle = 'black';
    ctx.stroke();
  }

  if (node.isContext) {
    const rect = rectBloat(bounds, BLOCKS_VIEW_CONTEXT_WIDTH * pixel);

    ctx.beginPath();
    roundRect(rect, BLOCKS_VIEW_NODE_BORDERRADIUS);
    ctx.lineWidth = BLOCKS_VIEW_CONTEXT_WIDTH * pixel;
    ctx.strokeStyle = BLOCKS_VIEW_CONTEXT_COLOR;
    ctx.stroke();
  }

  if (isGroup(node) && node.open) {
    /* draw group bounds and background */

    const stripeBottom =
      bounds[1] +
      BLOCKS_VIEW_NODE_PAD +
      Math.max(
        HEADER_INNER_HEIGHT,
        getLabelHeight(node.displayLabel[0], BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT)
      ) +
      BLOCKS_VIEW_NODE_SEP;

    // header part in node color
    const isComponent =
      node.modelId && componentInterface.isComponent(node.modelId);
    const stripeColor = isComponent ? nodeColor : bg;

    ctx.beginPath();
    roundRect(
      [bounds[0], bounds[1], bounds[2], stripeBottom],
      [BLOCKS_VIEW_NODE_BORDERRADIUS, BLOCKS_VIEW_NODE_BORDERRADIUS, 0, 0]
    );
    ctx.fillStyle = stripeColor;
    ctx.fill();
    // #region a line at the bottom of the header stripe
    const [x1, , x2] = bounds;
    ctx.moveTo(x1, stripeBottom);
    ctx.strokeStyle = BLOCKS_VIEW_LEAF_BORDERCOLOR;
    ctx.lineTo(x2, stripeBottom);
    ctx.lineWidth = lineWidth;
    ctx.stroke();
    // #endregion
    // background part in node background

    ctx.beginPath();
    roundRect(
      [bounds[0], stripeBottom, bounds[2], bounds[3]],
      [0, 0, BLOCKS_VIEW_NODE_BORDERRADIUS, BLOCKS_VIEW_NODE_BORDERRADIUS]
    );
    ctx.fillStyle = bg;
    ctx.fill();

    // border in node border

    ctx.beginPath();
    roundRect(
      [bounds[0], bounds[1], bounds[2], bounds[3]],
      BLOCKS_VIEW_NODE_BORDERRADIUS
    );
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = lineWidth;
    ctx.stroke();

    /* now draw what's inside */

    let left = body[0];
    let right = body[2];
    let top = body[1];
    const bottom = top + HEADER_INNER_HEIGHT;

    {
      const sz = HEADER_INNER_HEIGHT;
      right = left + sz;
      const expanderColor = readableButtonColor(stripeColor);
      drawExpander(node, groupExpanderRect(bounds), expanderColor);
      left = right + SMALL_SEP;
    }

    if (node.representationData) {
      const sz = HEADER_INNER_HEIGHT;
      right = left + sz;
      const rc: Rectangle = [left, top, right, bottom];

      drawRepresentationData(rectCenter(rc), sz);
      left = right + SMALL_SEP;
    }

    if (mainLabel.length) {
      const sz = BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT;
      const cursor: Vector = [left, body[1] + 0.5 * sz];

      ctx.fillStyle = contrastColor;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.font = BLOCKS_VIEW_NODE_LABEL_FONT;

      for (const line of mainLabel) {
        ctx.fillText(line, ...cursor);

        cursor[1] += BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT;
      }

      top = cursor[1];
    }

    /* multilabels */
    const multiLabelsTop = stripeBottom + SMALL_SEP;
    const [bodyLeft, , bodyRight] = body;
    const multiLabelsBottom = drawMultilabels(
      bodyLeft,
      multiLabelsTop,
      bodyRight,
      false
    );

    if (subLabel.length) {
      const sz = BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT;
      const cursor: Vector = [bodyLeft, multiLabelsBottom + 0.5 * sz];

      ctx.fillStyle = colors.grey50;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.font = BLOCKS_VIEW_NODE_SUBLABEL_FONT;

      for (const line of subLabel) {
        ctx.fillText(line, ...cursor);

        cursor[1] += BLOCKS_VIEW_NODE_LABEL_FONTHEIGHT;
      }

      top = cursor[1];
    }

    return;
  }

  /* node outline and fill */

  ctx.beginPath();
  roundRect(bounds, BLOCKS_VIEW_NODE_BORDERRADIUS);

  ctx.fillStyle = bg;
  ctx.strokeStyle = borderColor;
  ctx.lineWidth = lineWidth;
  ctx.fill();
  ctx.stroke();

  if (isGroup(node) && !node.open) {
    let left = body[0];
    let right = body[2];
    const top = body[1];
    const bottom = top + HEADER_INNER_HEIGHT;

    {
      const expanderColor = readableButtonColor(bg);
      const sz = HEADER_INNER_HEIGHT;
      right = left + sz;
      drawExpander(node, groupExpanderRect(bounds), expanderColor);
      left = right + SMALL_SEP;
    }

    {
      const sz = HEADER_INNER_HEIGHT;
      right = body[2];
      left = right - sz;

      drawBadge(node, [left, top, right, bottom], bg, textColor);
    }

    {
      const headerBottom = top + HEADER_INNER_HEIGHT + BLOCKS_VIEW_NODE_SEP;

      ctx.beginPath();
      ctx.moveTo(bounds[0], headerBottom);
      ctx.lineTo(bounds[2], headerBottom);
      ctx.strokeStyle = BLOCKS_VIEW_LEAF_BORDERCOLOR;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    }
  }

  /* representation data and label */
  const mainLabelHeight = mainLabel.length * BLOCKS_VIEW_NODE_LABEL_LINE_HEIGHT;
  const contentHeight =
    BLOCKS_VIEW_LEAF_RD_INNERSIZE +
    (mainLabelHeight ? SMALL_SEP + mainLabelHeight : 0) +
    (multilabelHeight ? SMALL_SEP + multilabelHeight : 0);
  const [centerX, centerY] = rectCenter(bounds);
  let y = centerY - contentHeight / 2;

  if (node.representationData) {
    if (node.representationData.shapeName && !node.representationData.isImage) {
      const { width: shapeWidth } = determineStyleSize(
        `#${node.representationData.shapeName}`
      );
      const shapeX = centerX - shapeWidth / 2;
      const shapeY = y;
      const shapeBounds: Rectangle = [
        shapeX,
        shapeY,
        shapeWidth,
        BLOCKS_VIEW_LEAF_RD_INNERSIZE,
      ];
      drawShape(
        ctx,
        nodeColor,
        contrastColor,
        node.representationData.shapeName,
        shapeBounds
      );
    } else {
      ctx.beginPath();
      const circleRadius = BLOCKS_VIEW_LEAF_RD_INNERSIZE / 2;
      const circleCenterY = y + circleRadius;
      ctx.ellipse(
        centerX,
        circleCenterY,
        circleRadius,
        circleRadius,
        0,
        0,
        2 * Math.PI
      );
      ctx.fillStyle = nodeColor;
      ctx.fill();

      drawRepresentationData(
        [centerX, circleCenterY],
        BLOCKS_VIEW_LEAF_RD_INNERSIZE
      );
    }

    y += BLOCKS_VIEW_LEAF_RD_INNERSIZE + SMALL_SEP;
  }

  if (mainLabel.length !== 0) {
    ctx.fillStyle = textColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = BLOCKS_VIEW_NODE_LABEL_FONT;
    for (const line of mainLabel) {
      ctx.fillText(line, centerX, y + BLOCKS_VIEW_NODE_LABEL_LINE_HEIGHT / 2);
      y += BLOCKS_VIEW_NODE_LABEL_LINE_HEIGHT;
    }
  }

  if (subLabel.length !== 0) {
    /* sublabel */
  }

  drawMultilabels(body[0], y + SMALL_SEP, body[2], true);
};

export const renderDragGhost = (
  node: BlocksViewNode,
  context: CanvasRenderingContext
) => {
  if (node.cell) {
    drawNode(node, node.cell, DrawNodeOpacity.NORMAL, context);
  }
};
