import {
  ComponentLabelSource,
  DiffType,
  GetCssClassNamesOption,
  GlobalReferenceType,
  GraphModelMap,
  GraphModelShape,
} from '@ardoq/data-model';
import {
  APIComponentAttributes,
  APIComponentAttributesLite,
  APIEntityType,
  APIFieldType,
  ArdoqId,
  PersistedExternalDocument,
} from '@ardoq/api-types';
import { ComponentBackboneModel, FieldBackboneModel } from 'aqTypes';
import {
  AugmentedModel,
  getAttributeOfModel,
  getAttributesOfModel,
  StringKeys,
} from 'modelInterface/genericInterfaces';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import CurrentUser from 'models/currentUser';
import Component from 'models/component';
import Workspaces from 'collections/workspaces';
import Fields from 'collections/fields';
import Filters, { currentFilterColor } from 'collections/filters';
import graphModel$ from 'modelInterface/graphModel$';
import { getCurrentComponentLabelFormattingSetting } from 'modelInterface/util';
import { logError } from '@ardoq/logging';
import { ExcludedSet } from 'collections/consts';
import { CollectionView } from 'collections/consts';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { getScopeComponents as getAllScopeComponents } from 'loadedScenarioData';
import Components from 'collections/components';
import { loadedScenarioData$ } from 'loadedScenarioData$';
import { backboneModelComparator } from 'utils/compareUtils';
import { getWorkspaceEntities } from 'collectionInterface/genericInterfaces';
import { getCollectionForEntityType } from 'collectionInterface/utils';
import {
  CanComponentBeUnlocked,
  CanComponentHaveChildren,
  Compare,
  CreateLocalComponent,
  GetAllComponentsPartial,
  GetAttribute,
  GetAttributes,
  GetChangedAttributes,
  GetChildren,
  GetComponentColor,
  GetComponentData,
  GetComponentsByWorkspaceId,
  GetComponentsCount,
  GetCssClassNames,
  GetFilterColorClassNames,
  GetComponentFilterColor,
  GetDescription,
  GetFieldLabelAndValue,
  GetNameWithFieldLabelAndValue,
  GetFieldValue,
  GetFields,
  GetFormattedLabel,
  GetFullPathName,
  GetGlobalReferenceTypes,
  GetImage,
  GetLevel,
  GetModelId,
  GetName,
  GetParentId,
  GetRawLabel,
  GetRepresentation,
  GetRepresentationData,
  GetRootComponents,
  GetScopeComponents,
  GetShape,
  GetSources,
  GetSubtreeDepth,
  GetSubtreeIds,
  GetTargets,
  GetTreeDepth,
  GetType,
  GetTypeId,
  GetTypeName,
  GetTypeNameFilter,
  GetVersion,
  GetVisualDiffType,
  GetWorkspaceId,
  HasChangedAttribute,
  HasParentChanged,
  HasURLFields,
  IsComponent,
  IsDescendant,
  IsExternallyManaged,
  IsExternallyMissing,
  IsIncludedInContextByFilter,
  IsLocked,
  IsScenarioContextComponent,
  IsScenarioRelated,
  RemoveFromCollection,
  SetAttributes,
  SetLock,
  GetComponentDisplayColorAsSVGAttributes,
  GetSubdivisionMembership,
  GetSubdivisionName,
  GetComponentAncetryIds,
  GetConditionalFormattingState,
  ConditionalFormattingState,
} from '@ardoq/component-interface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DIFF_TYPE } from '@ardoq/global-consts';
import enhancedScopeDiffData$ from 'scope/enhancedScopeDiffData$';
import { pick } from 'lodash';
import { vizFilterGray } from '@ardoq/design-tokens';
import { getDefaultCSSColor, getFillAndStroke } from '@ardoq/color-helpers';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { fieldUtils } from '@ardoq/scope-data';
import { formatUserField } from 'modelInterface/formatUserField';

// NOTE:
// The purpose of BB interfaces is to avoid exposing the BB model. In practice
// this means you should __never__ return a BB model from these functions.

type GetIdFromCid = (cid: string) => ArdoqId;

const getGlobalReferenceTypes: GetGlobalReferenceTypes = componentIds => {
  const targetReferenceTypes = _getRefTypes(componentIds, graphModel.targetMap);
  const sourceReferenceTypes = _getRefTypes(componentIds, graphModel.sourceMap);
  return { targetReferenceTypes, sourceReferenceTypes };
};

const getAttributesConfig: [
  Backbone.Collection<AugmentedModel<APIComponentAttributes>>,
  Map<StringKeys<APIComponentAttributes>, StringKeys<APIComponentAttributes>>,
] = [Components.collection, new Map([['id', '_id']])];

const getAttributes: GetAttributes =
  getAttributesOfModel<APIComponentAttributes>(...getAttributesConfig);

const getAttribute: GetAttribute = getAttributeOfModel<APIComponentAttributes>(
  ...getAttributesConfig
);

const getLiteAttributes = (componentId: ArdoqId) => {
  const model = Components.collection.get(componentId);
  if (!model) return null;

  // Note: _.pick did not play nice with TypeScript
  return {
    _id: model.attributes._id,
    _version: model.attributes._version,
    name: model.attributes.name,
    model: model.attributes.model,
    type: model.attributes.type,
    typeId: model.attributes.typeId,
    rootWorkspace: model.attributes.rootWorkspace,
    lock: model.attributes.lock,
    subdivisionMembership: structuredClone(
      model.attributes.subdivisionMembership
    ),
  } satisfies APIComponentAttributesLite;
};

const getSources: GetSources = componentId =>
  graphModel.targetMap.get(componentId) || [];

const getTargets: GetTargets = componentId =>
  graphModel.sourceMap.get(componentId) || [];

const isIncludedInContextByFilter: IsIncludedInContextByFilter =
  componentId => {
    const component = Components.collection.get(componentId);
    return component ? component.isIncludedInContextByFilter() : true;
  };

const getFormattedLabel: GetFormattedLabel = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getRawLabel() : null;
};

const getCssClassNames: GetCssClassNames = (componentId, options) => {
  const component = Components.collection.get(componentId);
  return component ? component.getCSS(options) : null;
};

const getTypeId: GetTypeId = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getTypeId() : null;
};

const getType: GetType = componentId => {
  const component = Components.collection.get(componentId);
  if (!component) return null;

  const type = component.getMyType();
  return type ?? null;
};

const getTypeName: GetTypeName = componentId => {
  const type = getType(componentId);
  if (!type) return null;
  return type.name;
};

const getRepresentationData: GetRepresentationData = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getRepresentationData() : null;
};

const getExternalDocumentData: (
  componentId: ArdoqId
) => PersistedExternalDocument[] = componentId => {
  const component = Components.collection.get(componentId);
  return component?.attributes.ardoq?.externalDocuments ?? [];
};

const getRepresentation: GetRepresentation = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getRepresentation() : null;
};

const getChildren: GetChildren = (
  componentId,
  ignoreSort = false,
  nodeCollectionView = CollectionView.DEFAULT_VIEW
) => {
  const component = Components.collection.get(componentId);
  return component
    ? component
        .getChildren(ignoreSort, nodeCollectionView)
        .map<ArdoqId>(comp => getComponentId(comp))
    : [];
};

const getSubtreeIds: GetSubtreeIds = (componentId, ignoreSort = false) => {
  const component = Components.collection.get(componentId);
  return component
    ? component.getChildrenDeep(ignoreSort).map(getComponentId)
    : [];
};

const isWorkspaceEmpty = (workspaceId: ArdoqId) =>
  !Components.collection.some(
    component => component.get('rootWorkspace') === workspaceId
  );
const getRootComponents: GetRootComponents = (workspaceId, view) => {
  const collection =
    (view && Components.collection.views.get(view)) || Components.collection;
  return workspaceInterface.workspaceExists(workspaceId)
    ? collection
        .getWorkspaceComponents({ parent: null }, workspaceId)
        .map(comp => getComponentId(comp))
    : [];
};

const getRawLabel: GetRawLabel = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getRawLabel() : null;
};
const getDisplayLabel: GetRawLabel = componentId =>
  Components.collection.get(componentId)?.getRawLabel(false) ?? null;

const hasURLFields: HasURLFields = componentId => {
  const component = Components.collection.get(componentId);
  return component
    ? Fields.collection
        .getByComponent(component)
        .filter(
          (field: FieldBackboneModel) => field.getType() === APIFieldType.URL
        )
        .map((field: FieldBackboneModel) => component.get(field.get('name')))
        .some(url => url)
    : false;
};

const isComponent: IsComponent = componentId =>
  Components.collection.get(componentId) instanceof Component.model;

const getComponentColor: GetComponentColor = componentId =>
  Components.collection.get(componentId)?.getColor() ?? null;

const getImage: GetImage = componentId => {
  const component = Components.collection.get(componentId);
  return component?.getImage() ?? null;
};

const getShape: GetShape = componentId => {
  const component = Components.collection.get(componentId);
  return component?.getShape() ?? null;
};

const getWorkspaceId: GetWorkspaceId = componentId => {
  const component = Components.collection.get(componentId);
  if (!component) {
    return null;
  }
  const workspace = component.getWorkspace();
  if (!workspace) {
    logError(Error('Workspace not found.'), null, {
      componentId,
      workspaceId: component.get('rootWorkspace'),
      loadedWorkspaceCount: Workspaces.collection.length,
    });
    return null;
  }
  return workspace.id;
};

const getTreeDepth: GetTreeDepth = componentId => {
  const parent = getParentId(componentId);
  return parent ? getTreeDepth(parent) + 1 : 0;
};

const getSubtreeDepth: GetSubtreeDepth = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getSubtreeDepth() : 0;
};

const getFieldLabelAndValue: GetFieldLabelAndValue = (
  componentId,
  returnIfFormattingType = true
) => {
  const component = Components.collection.get(componentId);

  if (!component) {
    return null;
  }

  const activeCompLabelFilter = Filters.getCompLabelFilter();
  const currentSetting: string = activeCompLabelFilter[0]?.get('value');

  if (currentSetting === ComponentLabelSource.TYPE && !returnIfFormattingType) {
    return null;
  }

  const fieldLabel = Fields.collection
    .getByName(currentSetting, { acrossWorkspaces: true })
    ?.getRawLabel();
  const fieldValue = component.attributes[currentSetting];
  const fieldAttributes = component
    .getFields()
    .find(field => field.name() === currentSetting)?.attributes;
  const excludeTime = !activeCompLabelFilter[0]?.attributes?.includeTime;

  const formattedValue =
    fieldUtils.fieldAttributesToLabel({
      fieldAttributes,
      value: fieldValue,
      excludeTime,
      formatUserFieldFn: formatUserField,
    }) ??
    fieldValue ??
    null;

  if (!formattedValue && formattedValue !== 0) {
    return null;
  }

  if (!fieldLabel) {
    return `[${formattedValue}]`;
  }

  return `[${fieldLabel}: ${formattedValue}]`;
};

const getNameWithFieldLabelAndValue: GetNameWithFieldLabelAndValue =
  componentId => {
    const currentSetting = getCurrentComponentLabelFormattingSetting();
    if (currentSetting === ComponentLabelSource.NONE) {
      return null;
    }

    const name = getDisplayName(componentId);
    if (!name) {
      return null;
    }

    const fieldLabelAndValue = getFieldLabelAndValue(componentId);
    return `${name}${fieldLabelAndValue ? `\n${fieldLabelAndValue}` : ''}`;
  };

const getFields: GetFields = componentId => {
  const component = Components.collection.get(componentId);
  return component
    ? component
        .getFields()
        .map(field => Fields.collection.get(field.id)?.toJSON())
        .filter(ExcludeFalsy)
    : [];
};

const getDescription: GetDescription = componentId => {
  const component = Components.collection.get(componentId);
  return component?.getDescription() || null;
};

let graphModel: GraphModelShape = {
  sourceMap: new Map(),
  targetMap: new Map(),
  referenceMap: new Map(),
  referenceTypes: [],
};
graphModel$.subscribe(graph => (graphModel = graph));

const _getRefTypes = (componentIds: ArdoqId[], map: GraphModelMap) =>
  Object.values(
    Object.fromEntries(
      componentIds
        .flatMap((componentId: ArdoqId) =>
          (map.get(componentId) || []).map(({ referenceId }) => {
            const refType =
              referenceInterface.getGlobalReferenceType(referenceId);
            return refType ? [refType.id, refType] : null;
          })
        )
        .filter(ExcludeFalsy)
    )
  ) as GlobalReferenceType[];

const getComponentId = (component: ComponentBackboneModel) =>
  component.id || component.cid;

const getParentId: GetParentId = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.get('parent') : null;
};

const isDescendant: IsDescendant = (parentIdSet, componentId) => {
  const componentParentId = getParentId(componentId);
  if (!componentParentId) {
    return false;
  }
  return (
    parentIdSet.has(componentParentId) ||
    isDescendant(parentIdSet, componentParentId)
  );
};

const getLevel: GetLevel = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getLevel() : -1;
};

const getFieldValue: GetFieldValue = (componentId, fieldName) => {
  const component = Components.collection.get(componentId);
  return component ? component.get(fieldName) : null;
};
const getModelId: GetModelId = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getModelId() : null;
};

const isLocked: IsLocked = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getLock() : false;
};

const getManagedStatesForComponent = (componentId: ArdoqId) => {
  const component = Components.collection.get(componentId);
  if (!component) {
    return;
  }
  const jobIds = workspaceInterface
    .getWorkspaceIntegrations(component.get('rootWorkspace'))
    .map(x => x._id);
  const managedData = component.attributes.ardoq?.persistent?.managed || {};
  return jobIds.filter(x => managedData[x]).map(x => managedData[x]);
};

const isExternallyManaged: IsExternallyManaged = componentId => {
  const activeManagedDefinitions = getManagedStatesForComponent(componentId);
  return Boolean(
    activeManagedDefinitions &&
      activeManagedDefinitions.find(x => x.seenInLastImport)
  );
};

const isExternallyMissing: IsExternallyMissing = componentId => {
  const activeManagedDefinitions = getManagedStatesForComponent(componentId);
  return Boolean(
    activeManagedDefinitions &&
      activeManagedDefinitions.length > 0 &&
      activeManagedDefinitions.every(x => !x.seenInLastImport)
  );
};

const isScenarioRelated: IsScenarioRelated = componentId => {
  const components = Components.collection.getExcludedIds(
    ExcludedSet.SCENARIO_RELATED_SET
  );
  return components.has(componentId);
};

const getScopeComponents: GetScopeComponents = ({
  excludeDescendants = false,
  view = CollectionView.DEFAULT_VIEW,
}) => {
  const collectionView = Components.collection.views.get(view);
  if (!collectionView) {
    return [];
  }
  const viewIds = new Set(collectionView.map<ArdoqId>(comp => comp.id));
  // This includes deleted components.
  const scopeComponents = getAllScopeComponents();
  const scopeComponentSet = new Set(scopeComponents);
  return scopeComponents.filter(
    id =>
      viewIds.has(id) &&
      (!excludeDescendants || !isDescendant(scopeComponentSet, id))
  );
};

const getChangedAttributes: GetChangedAttributes = componentId => {
  const component = Components.collection.get(componentId);
  return component ? Object.keys(component.changed) : [];
};
const hasChangedAttribute: HasChangedAttribute = (componentId, attribute) =>
  getChangedAttributes(componentId).includes(attribute);

/* returns classnames for CSS Model manager, NOT the color string */
const getFilterColorClassNames: GetFilterColorClassNames = componentId =>
  Components.collection.get(componentId)?.getCSSFilterColor() ?? null;

/* returns the component filter color as a valid color string */
const getComponentFilterColor: GetComponentFilterColor = componentId => {
  const component = Components.collection.get(componentId);
  return component ? currentFilterColor(component) : null;
};

const isScenarioContextComponent: IsScenarioContextComponent = componentId =>
  loadedScenarioData$.state.contextComponents.has(componentId);

const getDisplayName: GetName = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.get('name') : null;
};

const getFullPathName: GetFullPathName = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.getFullPathName() : null;
};

const getComponentData: GetComponentData = componentId => {
  const component = Components.collection.get(componentId);
  return component ? component.toJSON() : null;
};

/**
 * Compares two components for the purpose of sorting,
 * using the user's currently selected sort.
 */
const compare: Compare = (componentIdA, componentIdB) => {
  const componentA = Components.collection.get(componentIdA);
  const componentB = Components.collection.get(componentIdB);
  return !componentA || !componentB
    ? 0
    : backboneModelComparator(componentA, componentB);
};

const setLock: SetLock = (componentId, value) => {
  const component = Components.collection.get(componentId);
  if (!component) {
    return;
  }
  if (value) {
    component.lock();
  } else {
    component.unlock();
  }
};

const getIdFromCid: GetIdFromCid = cid => {
  const component = Components.collection.get(cid);
  return component?.id ?? '';
};

const getVersion: GetVersion = componentId =>
  Components.collection.get(componentId)?.get('_version') ?? null;

const setAttributes: SetAttributes = attributes =>
  Components.collection.get(attributes._id)?.set(attributes);

const copyZones = (sourceComponentId: ArdoqId, targetComponentId: ArdoqId) => {
  const subdivisionMembership =
    getAttribute(sourceComponentId, 'subdivisionMembership') || [];
  setAttributes({
    subdivisionMembership,
    _id: targetComponentId,
  });
};

const removeZones = (componentId: ArdoqId) => {
  setAttributes({
    subdivisionMembership: [],
    _id: componentId,
  });
};

const removeFromCollection: RemoveFromCollection = componentId => {
  const component = Components.collection.get(componentId);
  if (component) {
    Components.collection.remove(component);
  }
};
const getComponentsByWorkspaceId: GetComponentsByWorkspaceId = workspaceId => {
  return workspaceInterface.workspaceExists(workspaceId)
    ? Components.collection
        .getWorkspaceComponents(null, workspaceId)
        .map(comp => comp.toJSON())
    : [];
};

const getComponentHierarchy = (
  componentId: ArdoqId,
  getChildrenFn: (componentId: ArdoqId) => ArdoqId[] = getChildren
): Record<ArdoqId, { isFilteredOut: boolean; children?: ArdoqId[] }> => {
  const children = getChildrenFn(componentId);

  const isFilteredOut = !isIncludedInContextByFilter(componentId);

  if (children.length === 0) {
    return isFilteredOut
      ? {}
      : {
          [componentId]: {
            isFilteredOut,
          },
        };
  }

  const hierarchy: Record<
    ArdoqId,
    { isFilteredOut: boolean; children?: ArdoqId[] }
  > = {};

  children.forEach(componentId => {
    const childHierarchy = getComponentHierarchy(componentId);

    for (const key in childHierarchy) {
      hierarchy[key] = childHierarchy[key];
    }
  });

  const isRemovedNode = isFilteredOut && children.every(id => !hierarchy[id]);

  if (isRemovedNode) {
    return hierarchy;
  }

  hierarchy[componentId] = {
    isFilteredOut,
    children: children.filter(id => hierarchy[id]),
  };

  return hierarchy;
};

const getComponentsHierarchyByWorkspaceIds = (
  workspacesIds: ArdoqId[]
): Record<ArdoqId, { isFilteredOut: boolean; children?: ArdoqId[] }> =>
  workspacesIds.reduce(
    (acc, workspaceId: ArdoqId) => ({
      ...acc,
      ...getComponentHierarchy(workspaceId, getRootComponents),
    }),
    { root: { isFilteredOut: false, children: workspacesIds } }
  );

const canComponentHaveChildren: CanComponentHaveChildren = componentId =>
  Components.collection.get(componentId)?.canHaveChildren() ?? false;

const canComponentBeUnlocked: CanComponentBeUnlocked = componentId => {
  const component = Components.collection.get(componentId);
  return Boolean(component && CurrentUser.canUnlock(component));
};

const getTypeNameFilter: GetTypeNameFilter = table => id => {
  const { rootWorkspace, typeId } =
    Components.collection.get(id)?.attributes ?? {};
  return Boolean(rootWorkspace && typeId && table[rootWorkspace] === typeId);
};
const getVisualDiffType: GetVisualDiffType = componentId =>
  Components.collection.get(componentId)?.get(DIFF_TYPE) ?? DiffType.NONE;

const getAllPartial: GetAllComponentsPartial = (keys: string[]) =>
  Components.collection.map(({ attributes }) => pick(attributes, keys));

const getWorkspaceComponents = (id: ArdoqId) =>
  getWorkspaceEntities(APIEntityType.COMPONENT, id);

const saveAllChangedComponents = (isForced: boolean) =>
  getCollectionForEntityType(APIEntityType.COMPONENT).saveAllChangedModels(
    isForced
  );

const hasParentChanged: HasParentChanged = componentId =>
  Boolean(
    enhancedScopeDiffData$.state.diffData?.components.update?.[componentId]
      ?.parent
  );

const getComponentsCount: GetComponentsCount = () => {
  return Components.collection.length;
};

const getSubdivisionName: GetSubdivisionName = (subdivisionId: ArdoqId) => {
  return subdivisionsInterface.getSubdivisionById(subdivisionId)?.name || null;
};

const getSubdivisionMembership: GetSubdivisionMembership = (
  componentId: ArdoqId
) => {
  return (
    componentInterface.getAttribute(componentId, 'subdivisionMembership') || []
  );
};

const getFilterColors = () => Filters.getFilterColors();

const resolveComponentBaseColor = (componentId: ArdoqId) => {
  if (getFilterColors().length) {
    return vizFilterGray;
  }

  const componentColor = componentInterface.getComponentColor(componentId);
  if (componentColor) {
    return componentColor;
  }
  const type = getType(componentId);
  return type ? type.color || getDefaultCSSColor(type.level) : null;
};

const getComponentColorAsSVGAttributes = (
  componentId: ArdoqId,
  { useAsBackgroundStyle }: GetCssClassNamesOption
) => {
  const baseColor = resolveComponentBaseColor(componentId);
  if (!baseColor) {
    return { fill: undefined, stroke: undefined };
  }
  return getFillAndStroke(baseColor, useAsBackgroundStyle);
};

/**
 * Returns the CSS color for the fill and stroke of the component, the "final" clolor that should be
 * displayed in the UI.
 *
 * If conditional formatting is active, and there is no filter color for the component, it should return
 * the filter color or vizFilterGray, if no filter color is set.
 *
 * If conditional formatting is not active, it will return the component color or type color,
 * or visZilterGray if there is no component type.
 *
 */
const getComponentDisplayColorAsSVGAttributes: GetComponentDisplayColorAsSVGAttributes =
  (componentId, options) => {
    const filterColor = getComponentFilterColor(componentId);
    return filterColor
      ? getFillAndStroke(filterColor, options.useAsBackgroundStyle)
      : getComponentColorAsSVGAttributes(componentId, options);
  };

/**
 * Gets an enum value representing how conditional formatting affects the coloring of the component with the given ID.
 *
 * - If the component is not found: NORMAL
 * - If there is no conditional formatting active: NORMAL
 * - If conditional formatting is coloring the component: FORMATTED
 * - If conditional formatting is active, but not matching the component: DIM
 * @returns NORMAL, FORMATTED, or DIM.
 */
const getConditionalFormattingState: GetConditionalFormattingState = (
  componentId: ArdoqId
) => {
  const component = Components.collection.get(componentId);
  if (!component) {
    return ConditionalFormattingState.NORMAL;
  }
  const filterColors = getFilterColors();
  if (!filterColors.length) {
    return ConditionalFormattingState.NORMAL;
  }
  return component.getCSSFilterColor()
    ? ConditionalFormattingState.FORMATTED
    : ConditionalFormattingState.DIM;
};

/**
 * Creates a component using the Backbone model but without saving it to the
 * backend nor adding it to the Components collection.
 *
 * This is useful to initialize fields correctly, and is safe to run multiple
 * times, eg: `createLocalComponent(createLocalComponent(attributes))`
 */
const createLocalComponent: CreateLocalComponent = (
  attributes: Record<string, unknown>
) => {
  return Component.create(attributes, { silent: true }).toJSON();
};

/** @returns an array with the given component id at the end, preceded by its parent id, its parent's parent id, et cetera */
export const getComponentAncestryIds: GetComponentAncetryIds = componentId =>
  componentId
    ? [...getComponentAncestryIds(getParentId(componentId)), componentId]
    : [];

const hasWriteAccess = (componentId: ArdoqId) => {
  const component = Components.collection.get(componentId);
  if (!component) {
    return false;
  }

  return componentAccessControlOperation.canEditComponent({
    // The `component` of `canEditComponent` is read-only, so seems acceptable
    // to pass the attributes (BB internal state) directly.
    component: component.attributes,
    permissionContext: currentUserInterface.getPermissionContext(),
    subdivisionsContext: subdivisionsInterface.getSubdivisionsStreamState(),
  });
};

const belongsToFlexibleModel = (componentId: ArdoqId) => {
  const component = Components.collection.get(componentId);
  const model = component ? component.getMyModel() : null;
  return model ? model.isFlexible() : false;
};

/**
 * In Viewpoint mode, scopeComponentIds does not always contain the ID of every component which can be
 * displayed in a view. So we use the old Components collection here, to get the IDs, since we also should
 * avoid calling the Components.collection directly.
 */
const getAllComponentIds = (): ArdoqId[] =>
  Components.collection.map(({ id }) => id);

export const componentInterface = {
  belongsToFlexibleModel,
  canComponentBeUnlocked,
  canComponentHaveChildren,
  compare,
  copyZones,
  createLocalComponent,
  getAllPartial,
  getAttribute,
  getAttributes,
  getLiteAttributes,
  getChangedAttributes,
  getChildren,
  getComponentAncestryIds,
  getComponentColor,
  getComponentData,
  getComponentDisplayColorAsSVGAttributes,
  getConditionalFormattingState,
  getComponentFilterColor,
  getComponentHierarchy,
  getComponentsByWorkspaceId,
  getComponentsCount,
  getComponentsHierarchyByWorkspaceIds,
  getCssClassNames,
  getDescription,
  getDisplayLabel,
  getDisplayName,
  getFieldLabelAndValue,
  getFields,
  getFieldValue,
  getFilterColorClassNames,
  getFormattedLabel,
  getFullPathName,
  getGlobalReferenceTypes,
  getIdFromCid,
  getImage,
  getLevel,
  getModelId,
  getNameWithFieldLabelAndValue,
  getParentId,
  getRawLabel,
  getRepresentation,
  getRepresentationData,
  getExternalDocumentData,
  getRootComponents,
  getScopeComponents,
  getShape,
  getSources,
  getSubdivisionMembership,
  getSubdivisionName,
  getSubtreeDepth,
  getSubtreeIds,
  getTargets,
  getTreeDepth,
  getType,
  getTypeId,
  getTypeName,
  getTypeNameFilter,
  getVersion,
  getVisualDiffType,
  getWorkspaceComponents,
  getWorkspaceId,
  hasChangedAttribute,
  hasParentChanged,
  hasURLFields,
  hasWriteAccess,
  isComponent,
  isDescendant,
  isExternallyManaged,
  isExternallyMissing,
  isIncludedInContextByFilter,
  isLocked,
  isScenarioContextComponent,
  isScenarioRelated,
  isWorkspaceEmpty,
  removeFromCollection,
  removeZones,
  saveAllChangedComponents,
  setAttributes,
  setLock,
  getAllComponentIds,
};
